<div class="card card-custom" *ngIf="company">
    <div class="card-header">
      <h3 class="card-title">{{ 'companyProfile' | translate }}</h3>
      <div class="card-toolbar">
        <div class="card-toolbar">
          <button class="btn btn-primary mr-2" color="accent" (click)="onCreate()">{{ 'create' | translate }}</button>
      </div>
        <button mat-raised-button color="accent" (click)="onEdit()">
          <mat-icon>edit</mat-icon>
          {{ 'edit' | translate }}
        </button>
      </div>
    </div>
    <div class="profile-details m-5">
      <div class="profile-details-content d-flex">
        <div class="profile-details-avatar m-5">
          <img [src]="receivedLogo?.url" width="120" height="120" />
        </div>       
        <div class="m-5">
          <div class="d-flex flex-column mb-2">
            <label>{{ 'companyName' | translate }}</label>
            <b>{{company.nameTranslations['en'] | translate}}</b>
          </div>
          <div class="d-flex flex-column mb-2">
            <label>{{ 'createdAt' | translate }}</label>
            <b>{{company.createdAt | date:'short'}}</b>
          </div>
          <div class="d-flex flex-column mb-2">
            <label>{{ 'updatedAt' | translate }}</label>
            <b>{{company.updatedAt | date:'short'}}</b>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group class="card-body">
      <mat-tab label="{{ 'public' | translate }}">
        <app-company-details [company]="company"></app-company-details>
        <br>
        <mat-divider></mat-divider>
        <br>
        <app-company-logos [id]="id" (logosData)="receiveLogosData($event)"></app-company-logos>
      </mat-tab>
      <mat-tab label="{{ 'subscriptions' | translate }}">
        <app-company-subscriptions [id]="id"></app-company-subscriptions>
      </mat-tab>
      <mat-tab label="{{ 'legalDetails' | translate }}">
        <app-legal-details [id]="id"></app-legal-details>
      </mat-tab>
    </mat-tab-group>
  </div>