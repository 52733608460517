import { ProductType } from '../../constants';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Product } from '../api';

export function selectDependentValidation(validationRules: any[], productObjs: Product[]): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const products = control?.value;
    if (products) {
      for (const rule of validationRules) {
        const selectedProduct = rule.product;
        const dependentProduct = rule.dependentProduct;
        const errorMessage = rule.errorMessage;
        const filteredProducts = productObjs.filter(obj => products.includes(obj.id));

        const isSelected = filteredProducts.some(p => p.type === selectedProduct);
        const isDependentSelected = filteredProducts.some(p => p.type === dependentProduct);
        if (isSelected && !isDependentSelected) {
          return of({ [selectedProduct]: errorMessage });
        }
      }
    }

    return of(null);
  };
}
export const selectValidationRules = [
  {
    product: ProductType.KycIDProof,
    dependentProduct: ProductType.AIOCR,
    errorMessage: 'KycIDProof validation error: AIOCR must be selected',
  },
  {
    product: ProductType.KycLiveness,
    dependentProduct: ProductType.AIActiveLiveness,
    errorMessage: 'KycLiveness validation error: AIActiveLiveness must be selected',
  },
  {
    product: ProductType.KycExternalDataProvider,
    dependentProduct: ProductType.AIDataWH,
    errorMessage: 'KycExternalDataProvider validation error: AIDataWH must be selected',
  },
  {
    product: ProductType.KycAMLSanction,
    dependentProduct: ProductType.AIAMLSanction,
    errorMessage: 'KycAMLSanction validation error: AIAMLSanction must be selected',
  },
  {
    product: ProductType.KycAMLPEP,
    dependentProduct: ProductType.AIAMLPEP,
    errorMessage: 'KycAMLPEP validation error: AIAMLPEP must be selected',
  },
  {
    product: ProductType.KycFraudEmail,
    dependentProduct: ProductType.KycEmailProof,
    errorMessage: 'KycFraudEmail validation error: KycEmailProof must be selected',
  },
  {
    product: ProductType.KycFraudPhone,
    dependentProduct: ProductType.KycPhoneProof,
    errorMessage: 'KycFraudPhone validation error: KycPhoneProof must be selected',
  },
];
