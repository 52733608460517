import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Company, CompanySubscriptionService, PagedResult, Product, ProductService, SnackBarService, Subscription, SubscriptionService } from '@app/core';
import { ConfirmationDialogComponent } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { CompaniesService } from 'app/modules/core/services/api/companies.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { UpdateSubscriptionDialogComponent } from '../update-subscription-dialog/update-subscription-dialog.component';

@Component({
  selector: 'app-company-subscriptions',
  templateUrl: './company-subscriptions.component.html',
  styleUrls: ['./company-subscriptions.component.scss']
})
export class CompanySubscriptionsComponent implements OnInit {

  public pagedResult$?: Observable<PagedResult<Subscription>>;
  public currentLang: string;
  public companies?: Company[];
  public products?: Product[];
  @Input()
  public id?: string;

  constructor(
    private companySubService: CompanySubscriptionService,
    private subService: SubscriptionService,
    private productService: ProductService,
    private companyService: CompaniesService,
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private translateService: TranslateService
  ) { 
    this.currentLang = translateService.currentLang;
  }

  ngOnInit(): void {
    this.fetchCompanySubscriptions();
    this.companyService.getInfo().subscribe(companies => this.companies = companies);
    this.productService.getInfo().subscribe(products => this.products = products);
  }

  public pageChanged(event: PageEvent): void {
    this.fetchCompanySubscriptions(event.pageIndex * event.pageSize, event.pageSize);
  }

  private fetchCompanySubscriptions(start = 0, limit = 25): void {
    this.pagedResult$ = this.companySubService.get(this.id as string, {
      start,
      limit
    }).pipe(share());
  }
  
  public edit(s: Subscription){
    this.dialog.open(UpdateSubscriptionDialogComponent, {
      width: '500px',
      data: {
        subscription: s,
        companies: this.companies,
        products: this.products,
        currentLang: this.currentLang
      }
    }).afterClosed().subscribe((sub: Subscription) => {
      if (sub) {
        this.subService.update(sub).subscribe(() => {
          this.snackbar.openSuccess('updatedSuccessfully');
          this.fetchCompanySubscriptions();
        }, () => {
          this.snackbar.openError('updatedFailed');
        });
      }
    });
  }
  public delete(id: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.data.headerMessage = 'delete';
    dialogConfig.data.content = 'doYouWantToDeleteSubscription';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((response: boolean) => {
        if (response) {
          this.subService.delete(id).subscribe(() => {
            this.snackbar.openSuccess('deletedSuccessfully');
            this.fetchCompanySubscriptions();
          }, () => {
            this.snackbar.openError('deletionFailed');
          });
        }
      });
  }
}
