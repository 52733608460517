import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent{

  @Output()
  public imageSelected = new EventEmitter<File>();
  @Input()
  public initialImageURL?: string

  public imageURL: any;


  public uploadFile(files: File[]): void {
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    this.imageSelected.emit(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.imageURL = reader.result;
    };
  }
}
