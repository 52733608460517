import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Company, CompanySubscriptionService, Language, Logo, Product, ProductService, SnackBarService, Subscription, SubscriptionService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { CompaniesService } from 'app/modules/core/services/api/companies.service';
import { CreateSubscriptionDialogComponent } from 'app/modules/features/subscriptions/components/create-subscription-dialog/create-subscription-dialog.component';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent {

  @Input()
  public company?: Company;
  
  @Input()
  public languages?: Language[];
  @Input()
  public id?: string;
  @Output()
  editEvent = new EventEmitter();
  onEdit = () => {this.editEvent.emit();} 
  public receivedLogo?: Logo; 
  public companies?: Company[];
  public products?: Product[];
  currentLang: string;

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private subService: SubscriptionService,
    private companySubService: CompanySubscriptionService,
    private productService: ProductService,
    private companyService: CompaniesService,
    private translateService: TranslateService
  ) {
    this.currentLang = translateService.currentLang;
  }

  ngOnInit(): void {
    this.fetchCompanySubscriptions();
    this.companyService.getInfo().subscribe(companies => this.companies = companies);
    this.productService.getInfo().subscribe(products => this.products = products);
  }

  private fetchCompanySubscriptions(start = 0, limit = 25): void {
    this.companySubService.get(this.id as string, {
      start,
      limit
    }).pipe(share());
  }

  public receiveLogosData(logos?: Logo[] | undefined): void {
    if (logos && logos.length > 0) {
      this.receivedLogo = logos.find(logo => logo.logoType === 'Icon') || logos[0];
    }
  }

  public onCreate(): void {
    this.dialog.open(CreateSubscriptionDialogComponent, {
      width: '500px',
      data: {
        companyId: this.id,
        products: this.products,
        currentLang: this.currentLang
      }
    }).afterClosed().subscribe((sub: Subscription) => {
      if (sub) {
        this.subService.create(sub).subscribe(() => {
          this.snackbar.openSuccess('createdSuccessfully');
          this.fetchCompanySubscriptions();
        }, () => {
          this.snackbar.openError('creationFailed');
        });
      }
    });
  }
}
