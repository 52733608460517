export class ProductType {
  public static readonly KycIDProof = 'KycIDProof';
  public static readonly KycLiveness = 'KycLiveness';
  public static readonly KycFraudIP = 'KycFraudIP';
  public static readonly KycFraudEmail = 'KycFraudEmail';
  public static readonly KycFraudPhone = 'KycFraudPhone';
  public static readonly KycFraudIdentity = 'KycFraudIdentity';
  public static readonly KycFraudDevice = 'KycFraudDevice';
  public static readonly KycAddressProof = 'KycAddressProof';
  public static readonly KycInternalDataProvider = 'KycInternalDataProvider';
  public static readonly KycExternalDataProvider = 'KycExternalDataProvider';
  public static readonly KycVT = 'KycVT';
  public static readonly KycAMLSanction = 'KycAMLSanction'; 
  public static readonly KycAMLPEP = 'KycAMLPEP';
  public static readonly KycActiveMonitoringPEP = 'KycActiveMonitoringPEP';
  public static readonly KycActiveMonitoringSanction = 'KycActiveMonitoringSanction';
  public static readonly KycPhoneProof = 'KycPhoneProof';
  public static readonly KycEmailProof = 'KycEmailProof';
  public static readonly AIOCR = 'AIOCR';
  public static readonly AIFaceCompare = 'AIFaceCompare';
  public static readonly AIDataWH = 'AIDataWH';
  public static readonly AIAMLSanction = 'AIAMLSanction';
  public static readonly AIAMLPEP = 'AIAMLPEP';
  public static readonly AIPassiveLiveness = 'AIPassiveLiveness';
  public static readonly AIActiveLiveness = 'AIActiveLiveness';
  public static readonly AIBulkPEP = 'AIBulkPEP';
  public static readonly AIBulkSanction = 'AIBulkSanction';
  public static readonly OAuth = 'OAuth';
  public static readonly VCConsume = 'VCConsume';
  public static readonly VCIssue = 'VCIssue';
  public static readonly KYTAMLCrypto = 'KYTAMLCrypto';
  public static readonly Kyc = 'Kyc';

  public static get(): string[] {
    return [
        this.KycIDProof,
        this.KycLiveness,
        this.KycFraudIP,
        this.KycFraudEmail,
        this.KycFraudPhone,
        this.KycFraudIdentity,
        this.KycFraudDevice,
        this.KycAddressProof,
        this.KycInternalDataProvider,
        this.KycExternalDataProvider,
        this.KycVT,
        this.KycAMLSanction,
        this.KycAMLPEP,
        this.KycActiveMonitoringPEP,
        this.KycActiveMonitoringSanction,
        this.AIOCR,
        this.AIFaceCompare,
        this.AIDataWH,
        this.AIAMLSanction,
        this.AIAMLPEP,
        this.AIPassiveLiveness,
        this.AIActiveLiveness,
        this.OAuth,
        this.VCConsume,
        this.VCIssue,
        this.KYTAMLCrypto,
        this.KycEmailProof,
        this.KycPhoneProof,
        this.AIBulkPEP,
        this.AIBulkSanction,
        this.Kyc,
    ]
  }
}
