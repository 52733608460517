<div>
  <h5 mat-dialog-title>{{ 'createCompany' | translate }}</h5>
  <form [formGroup]="formGroup" fxLayout="column">
     <!-- Company type -->
     <mat-form-field color="accent">
      <mat-label>{{ 'typeOfClient' | translate }}</mat-label>
      <!-- <input matInput formControlName="category"> -->
      <mat-select formControlName="typeOfClient">
        <mat-option value="Verifier">{{ "verifier" | translate }}</mat-option>
        <mat-option value="Issuer">{{ "issuer" | translate }}</mat-option>
      </mat-select>

    </mat-form-field>
    <!-- Did -->
    <mat-form-field color="accent">
      <mat-label>{{ 'did' | translate }}</mat-label>
      <input matInput formControlName="did">
    </mat-form-field>

    <!-- VerKey -->
    <mat-form-field color="accent">
      <mat-label>{{ 'verKey' | translate }}</mat-label>
      <input matInput formControlName="verKey">
    </mat-form-field>

    <!-- Category -->
    <mat-form-field color="accent">
      <mat-label>{{ 'categories' | translate }}</mat-label>
      <!-- <input matInput formControlName="category"> -->
      <mat-select formControlName="categories" multiple>
        <mat-option *ngFor="let category of (categories$ | async)?.data" [value]="category.id">{{ category.name }}</mat-option>
      </mat-select>
    </mat-form-field>

     <!-- AgentUrl -->
     <mat-form-field color="accent">
      <mat-label>{{ 'agentUrl' | translate }}</mat-label>
      <input matInput formControlName="agentUrl">
    </mat-form-field>

    <!-- Email -->
    <mat-form-field color="accent">
      <mat-label>{{ 'email' | translate }}</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>

    <!-- Phone -->
    <mat-form-field color="accent">
      <mat-label>{{ 'phone' | translate }}</mat-label>
      <input matInput formControlName="phone">
    </mat-form-field>

    <!-- Website -->
    <mat-form-field color="accent">
      <mat-label>{{ 'website' | translate }}</mat-label>
      <input matInput formControlName="website">
    </mat-form-field>

    <!-- Name translations -->
    <div formGroupName="nameTranslations">
      <span>{{ 'nameTranslations' | translate }}</span>
      <div *ngFor="let language of languages" fxLayout="column">
        <mat-form-field color="accent">
          <mat-label>{{ language.code }}</mat-label>
          <input matInput [formControlName]="language.id">
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Actions -->
  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button (click)="onSave()" color="accent">{{ 'save' | translate }}</button>
  </div>
</div>