import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Category, Company, Language, PagedResult } from '@app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-company-dialog',
  templateUrl: './update-company-dialog.component.html',
  styleUrls: ['./update-company-dialog.component.scss']
})
export class UpdateCompanyDialogComponent implements OnInit {

  public company?: Company;
  public formGroup?: FormGroup;
  public languages: Language[];
  public categories$?: Observable<PagedResult<Category>>;
  public currentLanguage: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateCompanyDialogComponent>
  ) {
    console.log(data);
    this.company = data.company;
    this.languages = data.languages;
    this.currentLanguage = data.currentLanguage;
    this.categories$ = data.categories$;
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  private initializeFormGroup(): void {
    const nameTranslations = new FormGroup({});
    this.languages.forEach(language => {
      nameTranslations.addControl(language.id, new FormControl(this.company?.nameTranslations[language.code], Validators.required));
    });

    const companyCategoriesIds = this.company?.categories.map(c => c.id);

    this.formGroup = new FormGroup({
      nameTranslations: nameTranslations,
      email: new FormControl(this.company?.email, Validators.required),
      phone: new FormControl(this.company?.phone, Validators.required),
      website: new FormControl(this.company?.website, Validators.required),
      agentUrl: new FormControl(this.company?.agentUrl, Validators.required),
      categories: new FormControl(companyCategoriesIds)
    });
  }

  public get nameTranslations(): FormGroup {
    return this.formGroup?.get('nameTranslations') as FormGroup;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    this.formGroup?.markAllAsTouched();
    if (this.formGroup?.invalid) {
      return;
    }
    const company = this.formGroup?.getRawValue();
    company.id = this.company?.id;
    this.dialogRef.close(company);
  }
}
